<template>
  <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
    <vx-card title="รายการข้อความ">
      <vs-td>
        <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" type="border"
          size="small" icon-pack="feather" icon="icon-edit" class="mr-2 mb-4" @click="clearData() ,popupInfo('ADD')">
          เพิ่มข้อความ</vs-button>
      </vs-td>
      <vs-table :data="infoSettingMessage">
        <template slot="thead">
          <vs-th>
            ลำดับ
          </vs-th>
          <vs-th>
            ข้อความ
          </vs-th>
          <vs-th>
            แสดงในรายการ Drop down list
          </vs-th>
          <vs-th>
            ข้อความอัตโนมัติหลังสมัคร
          </vs-th>
          <vs-th>
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.id">
              {{ tr.id }}
            </vs-td>
            <vs-td :data="tr.text">
              {{ cutString(tr.text) }}
            </vs-td>

            <vs-td :data="tr.dropDownList">
              <vs-switch :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true"
                v-model="tr.dropDownList" @click="update_status(tr.id, tr.dropDownList ,'dropDownList')">
                <span slot="on">เปิด</span>
                <span slot="off">ปิด</span>
              </vs-switch>
            </vs-td>


            <vs-td :data="tr.autoMessage">
              <vs-switch :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true"
                v-model="tr.autoMessage"
                @click="update_status(tr.id, tr.autoMessage, 'autoMessage')">
                <span slot="on">เปิด</span>
                <span slot="off">ปิด</span>
              </vs-switch>
            </vs-td>

            <vs-td>
              <div style="display: flex; align-items: center">
                <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true"
                  type="border" class="mr-2" size="small" icon-pack="feather" color="warning" icon="icon-edit"
                  @click="popupInfo('EDIT', tr)">แก้ไข</vs-button>
                <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true"
                  type="border" class="mr-2" size="small" icon-pack="feather" color="success" icon="icon-eye"
                  @click="popupInfo('SHOW', tr)">แสดง</vs-button>
                <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true"
                  type="border" size="small" icon-pack="feather" color="danger" icon="icon-eye"
                  @click="popupDelete('DELETE',tr)">ลบ</vs-button>
              </div>

            </vs-td>
            <vs-td>

            </vs-td>

          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
    <vs-popup classContent="popup-example" :title="`${this.infopopup.type} MESSAGES`" :active.sync="showpopup">
      <div v-if="this.infopopup.type != 'SHOW'">
        <vs-textarea class="w-full" v-validate="'required|max:70'" label="ข้อความ" v-model="infopopup.message"
          name="message" style="resize: none" height="200px" />
        <span class="text-danger text-sm" v-show="errors.has('message')">{{ errors.first('message') }}</span>
        <p class="text-danger">ความยาวข้อความ {{ ckeckLength(infopopup.message)  }}/70</p>
      </div>
      <vs-textarea v-else class="w-full" v-validate="'required'" label="ข้อความ" v-model="infopopup.message"
        name="message" style="resize: none " height="200px" disabled />
      <div v-if="this.infopopup.type != 'ADD'">
        <br>
        <p> เวลาแก้ไขล่าสุด : {{ moment(infopopup.datetime).format("DD / MM / YYYY  HH:mm:ss")    }}</p>
        <br>
        <p> แก้ไขโดย : {{ infopopup.admin  }}</p>
      </div>

      <br>
      <vs-button v-if="this.infopopup.type != 'SHOW'"
        :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" size="small"
        icon-pack="feather" icon="icon-edit" class="mr-2 mt-4" @click="confirm_edit(infopopup.type)">ยืนยัน</vs-button>
    </vs-popup>
    <vs-popup classContent="popup-example" :title="`${this.infopopup.type} MESSAGES`" :active.sync="showpopupDelete">


      <vs-textarea class="w-full" v-validate="'required'" label="ข้อความ" v-model="infopopup.message" name="message"
        style="resize: none " height="200px" disabled />
      <p>หากต้องการลบ ข้อความนี้กรุณากดปุ่ม "ยืนยัน"</p>

      <br>
      <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" size="small"
        icon-pack="feather" icon="icon-edit" class="mr-2 mt-4" @click="deleteMessage(infopopup.id)">ยืนยัน</vs-button>
    </vs-popup>
  </div>
</template>


<script>
import axios from '../../../axios'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import {
  Validator
} from 'vee-validate'

const dict = {
  custom: {
    message: {
      required: 'กรุณากรอกข้อมูล',
      max:'กรุณากรอกตัวอักษรไม่เกิน70ตัว'
    }
  }
}
Validator.localize('en', dict)
export default {
  components: {
    flatPickr
  },
  data () {
    return {
      memberData: '',
      searchmember: '',
      infoSettingMessage: '',
      showpopup: false,
      showpopupDelete: false,
      infopopup: {
        message: '',
        datetime: '',
        admin: '',
        id: '',
        type: ''
      },
      date: '',
      error: '',
      onoff_status : ''
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },

  mounted () {
    this.callinfo()

  },
  methods: {
    callinfo () {
      axios
        .get('/callcenter/setting/list/message')
        .then(response => (this.infoSettingMessage = response.data))
    },
    popupInfo (Type, rowData) {
      if (Type === 'EDIT' || Type === 'SHOW') {
        this.infopopup.message = rowData.text
        this.infopopup.datetime = rowData.datetime
        this.infopopup.admin = rowData.admin
        this.infopopup.id = rowData.id
        this.showpopup = true
      } else {
        this.showpopup = true
      }
      this.infopopup.type = Type
    },
    popupDelete (Type, rowData) {
      this.infopopup.type = Type
      this.infopopup.message = rowData.text
      this.infopopup.id = rowData.id
      this.showpopupDelete = true
    },
    async deleteMessage (id) {

      await axios.post('/callcenter/setting/delete/message', {
        id

      })
        .then(response => (this.error = response.data))

      if (this.error.status === true) {
        this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'error',
          text: this.error.info.title,
          title: 'ทำรายการสำเร็จ'
        })
        this.callinfo()
        this.showpopupDelete = false
      } else {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          text: this.error.info.title,
          title: 'ทำรายการผิดพลาด'
        })
        this.showpopupDelete = false
      }
    },
    async confirm_edit (type) {
      if (this.infopopup.message.length === 0) {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          text: 'กรุณากรอกข้อมูล',
          title: 'ทำรายการไม่สำเร็จ'
        })

        return false
      }


      if (this.infopopup.message.length > 70) {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          text: 'ข้อความของท่านยาวเกิน 70 อักษร',
          title: 'ทำรายการไม่สำเร็จ'
        })

        return false
      }

      if (type === 'ADD') {

        await axios.post('/callcenter/setting/add/message', {
          message: this.infopopup.message

        })
          .then(response => (this.error = response.data))
      } else {

        await axios.post('/callcenter/setting/edit/message', {
          message: this.infopopup.message,
          id : this.infopopup.id

        })
          .then(response => (this.error = response.data))
      }

      if (this.error.status === true) {
        this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'error',
          text: this.error.info.title,
          title: 'ทำรายการสำเร็จ'
        })
        this.callinfo()
        this.showpopup = false
      } else {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          text: this.error.info.title,
          title: 'ทำรายการผิดพลาด'
        })
        this.showpopup = false
      }
    },
    clearData () {
      this.infopopup.message = ''
    },
    cutString (text) {
      return text.slice(0, 70)
    },
    ckeckLength (text) {
      return text.length
    },
    async update_status (id, status, type) {

      if (status === true) {
        status = false
      } else {
        status = true
      }

      await axios
        .post('/callcenter/setting/edit/onoff', {
          id,
          status,
          type
        }).then(response => (this.onoff_status = response.data))
      if (this.onoff_status.status === true) {
        this.$vs.notify({
          time:3000,
          color: status === true ? 'success' : 'warning',
          position:'top-right',
          icon:  status === true ? 'check_box' : 'error',
          title: status === true ? 'นำเข้ารายการ' : 'นำรายการออก',
          text:  status === true ? `หมวดข้อความ : ${type}` : `หมวดข้อความ : ${type}`
        })
        this.callinfo()
      } else {
        this.$vs.notify({
          time:8000,
          color: 'danger',
          position:'top-right',
          icon:  'error',
          title: this.onoff_status.status === false ? 'นำเข้ารายการไม่สำเร็จ!!' : 'นำรายการออกไม่สำเร็จ!!',
          text: this.onoff_status.info.title
        })
        this.callinfo()
      }
    }
  }
}

</script>

<style lang="scss">
  #profile-tabs {
    .vs-tabs--content {
      padding: 0;
    }
  }

</style>
